import { api } from "../../utils/api";
import { User } from "./types";

export async function getAuthenticatedUser(): Promise<User | null> {
	try {
		const { data } = await api.get<User>("/user");
		return data;
	} catch (error) {
		console.error(error);
	}
	return null;
}
