import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { FullPagePreloader } from "./components/Preloader";
import { AuthContext } from "./features/auth";

const SignInPage = React.lazy(() => import("./pages/SignIn"));
const SystemStatusPage = React.lazy(() => import("./pages/SystemStatus"));
const ProfilerPage = React.lazy(() => import("./pages/Profiler"));
const MkpFilesPage = React.lazy(() => import("./pages/MkpFiles"));
const SemaChartPage = React.lazy(() => import("./pages/SemaChart"));

export const App: React.FC = () => {
	const { isLoading, user } = React.useContext(AuthContext);
	if (isLoading) {
		return <FullPagePreloader />;
	}
	if (user === null) {
		return (
			<React.Suspense fallback={<FullPagePreloader />}>
				<SignInPage />
			</React.Suspense>
		);
	}
	return (
		<BrowserRouter>
			<React.Suspense fallback={<FullPagePreloader />}>
				<Routes>
					<Route path="/" element={<Navigate to="/mkp-files" replace={true} />} />
					<Route path="/system-status" element={<SystemStatusPage />} />
					<Route path="/profiler" element={<ProfilerPage />} />
					<Route path="/mkp-files" element={<MkpFilesPage />} />
					<Route path="/sema-chart-demo" element={<SemaChartPage />} />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
	);
};
