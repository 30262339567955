import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { App } from "./App";
import { AuthProvider } from "./features/auth";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<IntlProvider locale="hu" defaultLocale="hu">
			<AuthProvider>
				<App />
			</AuthProvider>
		</IntlProvider>
	</React.StrictMode>
);
