import React from "react";
import { AuthState, User } from "./types";
import { getAuthenticatedUser } from "./api";

export function useAuthProviderState(): AuthState {
	const [isLoading, setLoading] = React.useState(true);
	const [user, setUser] = React.useState<User | null>(null);

	React.useEffect(() => {
		const init = async (): Promise<void> => {
			setLoading(true);
			const user = await getAuthenticatedUser();
			setUser(user);
			setLoading(false);
		};
		init();
	}, []);

	return { isLoading, user };
}
