import React from "react";
import "./Preloader.css";

export const FullPagePreloader: React.FC = () => (
	<div className="fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center">
		<Preloader />
	</div>
);

export const ExpandedPreloader: React.FC = () => (
	<div className="grow flex items-center justify-center">
		<Preloader />
	</div>
);

export const Preloader: React.FC = () => (
	<div className="preloader">
		<div />
		<div />
		<div />
		<div />
	</div>
);
